/** IFrame  */

import { Box } from '@material-ui/core'
import React from 'react'
import PublicLayout from '../../layouts/public-layout'

export default () => {
    
    return (
        <PublicLayout contentPage={false} bottomMenu={false}>
            <iframe
                src="https://gamma.app/embed/8uursxuhipxuufn"
                style={{ width: '1200px', maxWidth: '100%', minHeight: '80vh', height: '100%', border: 'none' }}
                allow="fullscreen"
                title="Signatu's Consent Management Platform: GDPR Compliance and ISO/IEC TS 27560:2023 Integration"
            ></iframe>
        </PublicLayout>
    )
}
